<template>
  <l-tela titulo="Cadastro de cargos">
    <l-form :salvar="salvar">
      <i-basico
        :disabled="!novoRegistro"
        ref="inputNomeCargo"
        id="nomeCargo"
        label="Nome"
        v-model="form.nome"
        :validations="{ required: true, min: 3 }"
      />

      <v-treeview
        open-all
        selectable
        :items="items"
        v-model="form.permissoes"
      />

      <template v-slot:botoes>
        <v-btn @click="sair">Sair</v-btn>
        <v-btn color="primary" type="submit">Salvar</v-btn>
      </template>
    </l-form>
  </l-tela>
</template>

<script>
import cargoService from '@/services/cargo.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'
import menus from '@/menu'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  data() {
    return {
      novoRegistro: true,
      form: {
        _id: null,
        nome: '',
        permissoes: [],
      },
      items: [],
    }
  },
  beforeMount() {
    this.montaArvoreMenus()
  },
  mounted() {
    const { cargoId } = this.$route.params
    if (cargoId) {
      this.novoRegistro = false
      this.carregar(cargoId)
    } else {
      this.$refs.inputNomeCargo.definirFoco()
    }
  },
  methods: {
    montaArvoreMenus() {
      menus.forEach((item) => {
        let novoMenu = {
          id: item.id,
          name: item.label,
          children: [],
        }

        item.menus.forEach((children) => {
          novoMenu.children.push({
            id: children.permissaoNecessaria,
            name: children.label,
          })
        })

        this.items.push(novoMenu)
      })
    },
    async carregar(cargoId) {
      this.mostrarLoading()
      try {
        const { _id, nome, permissoes } = await cargoService.buscaPorId(cargoId)
        this.form = { _id, nome, permissoes }
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    async salvar() {
      this.mostrarLoading()
      try {
        const { _id, nome, permissoes } = this.form
        if (_id) {
          await cargoService.atualizar({ cargoId: _id, permissoes })
        } else {
          await cargoService.cadastrar({ nome, permissoes })
          this.sair()
        }
        this.alertaDadosSalvos()
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
    sair() {
      this.$router.push({ name: 'rota-cargos' })
    },
  },
}
</script>
