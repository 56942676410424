<template>
  <l-tela titulo="Cargos">
    <l-consulta
      :colunas="colunas"
      :registros="registros"
      :totalPaginas="paginas"
      @cliqueLinha="abrirAlteracao"
      @buscarPagina="buscar"
    />
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          fab
          large
          fixed
          bottom
          right
          v-bind="attrs"
          v-on="on"
          @click="abrirCadastro"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar novo cargo</span>
    </v-tooltip>
  </l-tela>
</template>

<script>
import cargoService from '@/services/cargo.service'
import LoadingMixin from '@/components/Mixins/Loading'
import NotificacaoMixin from '@/components/Mixins/Notificacao'

export default {
  mixins: [LoadingMixin, NotificacaoMixin],
  data() {
    return {
      colunas: [
        {
          text: 'Nome do cargo',
          align: 'start',
          sortable: false,
          value: 'nome',
          width: 500,
        },
      ],
      registros: [],
      paginas: 0,
    }
  },
  mounted() {
    this.buscar()
  },
  methods: {
    abrirCadastro() {
      this.$router.push({ name: 'rota-cargo-cadastrar' })
    },
    abrirAlteracao(registro) {
      const { _id } = registro
      if (_id) {
        this.$router.push({
          name: 'rota-cargo-alterar',
          params: { cargoId: _id },
        })
      }
    },
    async buscar(pagina = 1) {
      this.mostrarLoading()
      try {
        const cargos = await cargoService.buscaTodos(pagina)
        this.registros = cargos.lista
        this.paginas = cargos.pages
      } catch (error) {
        this.mostraNotificacao(error)
      } finally {
        this.fecharLoading()
      }
    },
  },
}
</script>
